import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminCreate = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [message, setMessage] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [admins, setAdmins] = useState([]); // برای ذخیره لیست ادمین‌ها

  const roles = [
    { value: 'admin', label: 'ادمین', description: 'دسترسی کامل به همه بخش‌ها و قابلیت تنظیمات زمانی و دسترسی به کاربران.' },
    { value: 'editor', label: 'ویرایشگر', description: '  قابلیت ویرایش محتوا و مدیریت مطالب، اما بدون دسترسی به تنظیمات کاربران و مدیریت زمان' },
  ];

  useEffect(() => {
    // بارگذاری ادمین‌ها از سرور
    const fetchAdmins = async () => {
      try {
        const response = await axios.get('https://api.asmary.com/data_admin/info');
        setAdmins(response.data); // فرض بر این است که سرور لیست ادمین‌ها را برمی‌گرداند
      } catch (error) {
        console.error('Error fetching admins:', error);
      }
    };

    fetchAdmins();
  }, []);

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    setRole(selectedRole);

    const selectedRoleObj = roles.find(role => role.value === selectedRole);
    setRoleDescription(selectedRoleObj ? selectedRoleObj.description : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await axios.post('https://api.asmary.com/data_admin/create', {
        username,
        password,
        role,
      });

      setMessage(response.data.message);
      // Reset form fields
      setUsername('');
      setPassword('');
      setRole('');
      setRoleDescription('');
      // Refresh the admin list
      const updatedAdmins = await axios.get('https://api.asmary.com/data_admin/info');
      setAdmins(updatedAdmins.data);
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred. Please try again.');
      }
    }
  };

  const handleDelete = async (username) => {
    setMessage('');

    try {
      const response = await axios.delete(`https://api.asmary.com/data_admin/delete/${username}`);
      setMessage(response.data.message);
      // Refresh the admin list
      const updatedAdmins = await axios.get('https://api.asmary.com/data_admin/info');
      setAdmins(updatedAdmins.data);
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="w-full h-[100%] overflow-scroll mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-xl sm:text-2xl font-bold mb-5 text-center">ایجاد ادمین جدید</h2>
      {message && <p className="text-red-500 mb-4 text-center">{message}</p>}

      {/* فرم ایجاد ادمین */}
      <form
        onSubmit={handleSubmit}
        className="bg-white p-4 sm:p-6 rounded-lg shadow-md mb-5 mx-auto"
      >
        <div className="mb-4">
          <label className="block text-gray-700 mb-2 text-sm" htmlFor="username">
            نام کاربری
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="border border-gray-300 p-2 w-full rounded text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2 text-sm" htmlFor="password">
            پسورد
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="border border-gray-300 p-2 w-full rounded text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2 text-sm" htmlFor="role">
            سطح دسترسی
          </label>
          <select
            id="role"
            value={role}
            onChange={handleRoleChange}
            required
            className="border border-gray-300 p-2 w-full rounded text-sm"
          >
            <option value="">انتخاب کنید</option>
            {roles.map((role) => (
              <option key={role.value} value={role.value}>
                {role.label}
              </option>
            ))}
          </select>
        </div>
        {role && (
          <div className="mb-4">
            <p className="text-gray-600 text-sm">توضیحات: {roleDescription}</p>
          </div>
        )}
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none text-sm"
        >
          ایجاد ادمین
        </button>
      </form>

      {/* لیست ادمین‌ها */}
      <h2 className="text-xl sm:text-2xl font-bold mb-5 text-center">لیست ادمین‌ها</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-md mb-5">
          <thead>
            <tr className="bg-gray-200 text-gray-700 text-xs sm:text-sm">
              <th className="py-2 px-4 border-b">نام کاربری</th>
              <th className="py-2 px-4 border-b">نقش</th>
              <th className="py-2 px-4 border-b">عملیات</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((admin) => (
              <tr key={admin.username} className="hover:bg-gray-100 text-xs sm:text-sm">
                <td className="py-2 px-4 border-b">{admin.username}</td>
                <td className="py-2 px-4 border-b">{admin.role}</td>
                <td className="py-2 px-4 border-b">
                  {admin.role !== "superadmin" ? (
                    <button
                      onClick={() => handleDelete(admin.username)}
                      className="bg-red-600 text-white py-1 px-3 rounded hover:bg-red-700 text-xs sm:text-sm"
                    >
                      حذف
                    </button>
                  ) : (
                    "سوپر ادمین توسط برنامه نویس ایجاد و حذف میشود."
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default AdminCreate;