import { useState, useEffect, useRef } from "react";
import { InView } from 'react-intersection-observer';
import './chat.css';
import BtnR from "../btnR/btnr";

const Main = () => {
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const chatWindowRef = useRef(null);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch('https://api.asmary.com/massegebox/messages', {
          method: 'GET',
          headers: {
            'x-api-key': '12345678' // استفاده از کلید API از متغیر محیطی
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch messages');
        }
        const data = await response.json();
        setMessages(data);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, []);

  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const handleScroll = () => {
      const chatWindow = chatWindowRef.current;
      if (chatWindow) {
        const messagesElements = chatWindow.querySelectorAll('.message');
        messagesElements.forEach((message) => {
          const rect = message.getBoundingClientRect();
          if (rect.top < 0 && Math.abs(rect.top) > 3000) {
            message.style.opacity = 0; // محو کردن پیام
          } else {
            message.style.opacity = 1; // نمایش پیام
          }
        });
      }
    };

    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      chatWindow.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatWindow) {
        chatWindow.removeEventListener('scroll', handleScroll);
      }
    };
  }, [messages]);

  return (
    <div id="abuteUs" className="chat-app mt-5 mb-5">
      <div
        className="conter chat-window h-full rounded-lg p-4 w-full"
        style={{
          display: 'flex',
          flexFlow: 'column-reverse wrap',
          alignContent: 'space-around',
          flexDirection: 'column',
        }}
        ref={chatWindowRef}
      >
        {messages.map((message) => (
          <InView
            key={message.id}
            threshold={0.5}
            onChange={(inView) => {
              if (inView) {
                setVisibleMessages((prev) => [...prev, message.id]);
              }
            }}
          >
            {({ ref, inView }) => (
              <div
                className="chat-window w-11/12 sm:w-1/2 md:w-3/5 lg:w-2/5 xl:w-2/5"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: message.sender === "user" ? "flex-end" : "flex-start",
                }}
              >
                <div
                  ref={ref}
                  data-id={message.id}
                  className={`message mb-4 ${message.sender === "other" ? "other" : "user"} ${inView ? "visible" : ""}`}
                  style={{
                    opacity: inView ? 1 : 0,
                    transform: inView ? "translateY(0)" : "translateY(20px)",
                    transition: "all 0.5s ease-out",
                  }}
                >
                  <div>{message.text}</div>
                </div>
              </div>
            )}
          </InView>
        ))}
      </div>
      <div className="mt-[80px]">
        <BtnR />
      </div>

    </div>
  );
};

export default Main;