import React, { Component } from "react";
import axios from "axios"; // یا از fetch استفاده کنید
import "./blog.css";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [], // حالت برای ذخیره داده‌های دریافت شده
      loading: true, // حالت برای نشان دادن وضعیت لودینگ
      error: null, // حالت برای ذخیره خطاها
    };
  }

  componentDidMount() {
    // دریافت داده‌ها از API هنگام mount شدن کامپوننت
    axios.get("https://api.asmary.com/blog/api/blogs")
      .then(response => {
        this.setState({
          blogs: response.data,
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  }

  render() {
    const { blogs, loading, error } = this.state;

    if (loading) {
      return <div></div>;
    }

    if (error) {
      return <div></div>;
    }

    return (
      <div className="logos">
        <div className="logos-slide">
          {Array.from({ length: 4 }).map((_, loopIndex) => (
            <React.Fragment key={loopIndex}>
              {blogs.map((blog, index) => (
                <React.Fragment key={index}>
                  <div className="blog">
                    {blog.content} {/* فرض کنید هر بلاگ یک عنوان (title) دارد */}
                  </div>
                  <div className="arrow">→</div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default Blog;