import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/home/home";
import Calender from "./components/reservation/reservation";
import Cursor from "./components/cursor/cursor";
import Panel from "./components/panel/panel";
import Login from "./components/login/login";
import { Toaster } from "react-hot-toast";
import "./App.css";
import Test from "./components/reservation/test";
import axios from "axios";
axios.defaults.headers.common["x-api-key"] = "q@12$3343YFW*£A£A£";
const App = () => {
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );

  useEffect(() => {
    // Update state if username is available in localStorage
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  // Handle login success
  const handleLoginSuccess = (user) => {
    setUsername(user);
    localStorage.setItem("username", user); // Save username in localStorage
  };
  const handleLogout = () => {
    setUsername(""); // Reset username
    localStorage.removeItem("username"); // Remove from localStorage
  };

  return (
    <>
      <Toaster />
      <Cursor />

      <Routes>
        <Route path="/" element={<Home />} />
        {/* Redirect to panel if user is already logged in */}
        <Route
          path="/login"
          element={
            username ? (
              <Navigate to={`/panel/${username}`} />
            ) : (
              <Login onLoginSuccess={handleLoginSuccess} />
            )
          }
        />
        {/* Protected route with username in the URL */}
        <Route
          path="/panel/:username"
          element={
            username ? (
              <Panel onLogout={handleLogout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/t" element={<Test />} />
        <Route path="/calender" element={<Calender />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default App;
