import { useEffect, useState } from "react";
import spliter from "./caracterf"; // Function to split the text into characters
import spliterf from "./caracterf"; // Function to split the Persian text into characters
import { motion, AnimatePresence } from "framer-motion";
import "./load.css";

// Parent animation variants for both texts
const containerVariants = {
  hidden: { opacity: 1 },
  reveal: {
    opacity: 1,
    transition: {
      staggerChildren: 0.02, // Delay between child animations
    },
  },
};

const containerVariantsf = {
  hidden: { opacity: 1 },
  reveal: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05, // Delay between child animations
    },
  },
};

// Character-level animation for text elements
const charVariants = {
  hidden: { opacity: 0, y: 50 }, // Start: characters are invisible and below
  reveal: { opacity: 1, y: 0 }, // End: characters appear and move up
};

// Exit animation for both text sections (English and Persian)
const exitVariants = {
  initial: { opacity: 1, y: 0, filter: "blur(0px)" },
  exit: {
    opacity: 0,
    y: -100,
    filter: "blur(10px)",  // Add blur to the exit animation
    transition: { duration: 1 }, // Duration for the exit animation
  },
};

function Loud() {
  // English and Persian texts
  const text1 = ` " Your position today is owed to the countless opportunities you didn’t miss. "`;
  const text2 = "Asmary - CEO";
  const text3 = `"موقعیت امروز شما به خاطر فرصت‌های بی‌شماری است که از دست نداده‌اید."`;
  const text4 = "مدیرعامل - اسماری";

  const [isEnglishVisible, setIsEnglishVisible] = useState(true); // Control text visibility
  const [isPersianVisible, setIsPersianVisible] = useState(false); // Control Persian text visibility
  const [animationStarted, setAnimationStarted] = useState(false); // Track when Persian text animation should start

  useEffect(() => {
    if (isEnglishVisible) {
      const timer = setTimeout(() => {
        setIsEnglishVisible(false); // Switch to Persian text after English text finishes
        setTimeout(() => setIsPersianVisible(true), 1000); // Delay Persian text visibility
      }, 4000); // Duration for English text display
      return () => clearTimeout(timer);
    }
  }, [isEnglishVisible]);

  const englishTextChars = spliter(text1);
  const persianTextChars = spliterf(text3);

  useEffect(() => {
    if (isPersianVisible && !animationStarted) {
      const timer = setTimeout(() => {
        setAnimationStarted(true); // Start Persian text animation after delay
      }, 600); // Wait for a second before starting Persian text animation
      return () => clearTimeout(timer);
    }
  }, [isPersianVisible, animationStarted]);

  return (
    <div className="load">
      <AnimatePresence>
        {isEnglishVisible ? (
          // English text display
          <motion.div
            key="english"
            variants={exitVariants}
            initial="initial"
            animate="reveal"
            exit="exit"
            style={{ fontFamily: "IRANSansXL" }}
          >
            <motion.h2
              className="text-center text-4xl leading-[60px] m-3 nowrap"
              variants={containerVariants}
              initial="hidden"
              animate="reveal"
              exit="exit"
            >
              {englishTextChars.map((char, index) => (
                <motion.span
                  key={index}
                  variants={charVariants}
                  className="inline-block"
                >
                  {char === " " ? "\u00A0" : char}
                </motion.span>
              ))}
            </motion.h2>

            <motion.h3
              className="text-center text-sm font-semibold mt-4 "
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 0.5 }}
              exit={{ opacity: 0, y: -100, filter: "blur(10px)", transition: { duration: 1 } }}
            >
              {text2}
            </motion.h3>
          </motion.div>
        ) : (
          // Persian text display
          <motion.div
            key="persian"
            variants={exitVariants}
            initial="initial"
            animate={animationStarted ? "reveal" : "initial"}
            dir="rtl"
            style={{ fontFamily: "IRANSansXL" }}
            exit="exit"
          >
            <motion.h2
              className="text-center text-4xl  leading-[60px] m-3"
              variants={containerVariantsf}
              initial="hidden"
              animate={animationStarted ? "reveal" : "hidden"}
              exit="exit"
            >
              {persianTextChars.map((char, index) => (
                <motion.span
                  key={index}
                  variants={charVariants}
                  className="inline-block"
                >
                  {char === " " ? "\u00A0" : char}
                </motion.span>
              ))}
            </motion.h2>
            <motion.h3
              className="text-center text-sm font-semibold mt-4"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1, duration: 0.5 }}
              exit={{
                opacity: 0, y: -100, filter: "blur(10px)", transition: {
                  duration: 1
                }
              }}
            >
              {text4}
            </motion.h3>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Loud;