import React, { useState, useEffect } from "react";
import Header from "../header/header";
import Main from "../main/main";
import ChatApp from "../chat/chat2";
import Loading from "../load/load";
import Banner from "../baner/baner";
import Blog from "../blog/blog";
import Footer from "../footer/footer";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // بررسی اینکه آیا کاربر قبلاً به صفحه اصلی مراجعه کرده است یا خیر
    const hasVisitedHome = sessionStorage.getItem("hasVisitedHome");

    if (!hasVisitedHome) {
      // اگر کاربر قبلاً به صفحه اصلی مراجعه نکرده باشد، لودینگ را نمایش بده
      setTimeout(() => {
        setLoading(false);
        sessionStorage.setItem("hasVisitedHome", "true"); // پرچم را در sessionStorage ذخیره کن
      }, 8000); // تنظیم مدت زمان ۸ ثانیه
    } else {
      // اگر کاربر قبلاً به صفحه اصلی مراجعه کرده باشد، لودینگ را خاموش کن
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loading /> // نمایش صفحه مشکی به مدت ۸ ثانیه
      ) : (
        <>
          <div className="header h-[50px] md:h-[100px]">
            <Header />
          </div>
          <Main />
          <Banner />
          <Blog />
          <ChatApp />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;