import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ManageDates from "./ManageDates";
import AddTime from './addtime';
import ChatboxEditor from "./chatboxeditor";
import UserInfo from "./userinfo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import BlogManager from "./blogManegment";
import FooterManager from "./footerManegment";
import BaberManager from './banerManegment';
import AdminCreate from "./adminManegment";

const Panel = ({ onLogout }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activePage, setActivePage] = useState("account");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");

    if (!storedUsername) {
      navigate("/login");
    } else {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(`https://api.asmary.com/data_admin/info/${storedUsername}`);
          setUserData(response.data);
        } catch (error) {
          setError("Unable to fetch data. Please try again later.");
        } finally {
          setLoading(false);
        }
      };

      fetchUserData();
    }
  }, [navigate]);

  const handleLogout = () => {
    onLogout();
  };

  const handleMenuClick = (page) => {
    setActivePage(page);
    setIsSidebarOpen(false);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await axios.put(`https://api.asmary.com/data_admin/update-password/${userData.username}`, {
        oldPassword,
        newPassword,
        confirmPassword,
      });

      setMessage(response.data.message);
      // Reset password fields
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An error occurred. Please try again.');
      }
    }
  };

  if (loading) {
    return <div>در حال بارگذاری...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex h-screen " dir="rtl">
      <div className={`fixed inset-0 bg-[#3864F2] text-white p-6 pl-10 space-y-4 transform transition-transform duration-500 ease-in-out ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} md:translate-x-0 md:relative md:w-64`} style={{ overflow: "scroll", zIndex: "10000" }}>
        <h2 className="text-2xl font-semibold mb-4">پنل مدیریتی</h2>
        <ul className="space-y-2 pr-5">
          <li
            onClick={() => handleMenuClick("account")}
            className={`p-3 cursor-pointer transition-all transform ${activePage === "account" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
          >
            حساب کاربری
          </li>
          {userData.role === 'superadmin' && (
            <>
              <li
                onClick={() => handleMenuClick("admins")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "admins" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                هم تیمی
              </li>
              <li
                onClick={() => handleMenuClick("manageDates")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "manageDates" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                مدیریت تاریخ
              </li>
              <li
                onClick={() => handleMenuClick("settings")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "settings" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات زمانی
              </li>
              <li
                onClick={() => handleMenuClick("reports")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "reports" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات پیامک ها
              </li>
              <li
                onClick={() => handleMenuClick("baner")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "baner" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات بنر
              </li>
              <li
                onClick={() => handleMenuClick("blog")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "blog" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات بلاگ
              </li>
              <li
                onClick={() => handleMenuClick("footer")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "footer" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات فوتر
              </li>
              <li
                onClick={() => handleMenuClick("support")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "support" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تایم های رزرو شده
              </li>
            </>
          )}
          {userData.role === 'admin' && (
            <>
              <li
                onClick={() => handleMenuClick("manageDates")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "manageDates" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                مدیریت تاریخ
              </li>
              <li
                onClick={() => handleMenuClick("reports")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "reports" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات پیامک ها
              </li>
              <li
                onClick={() => handleMenuClick("baner")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "baner" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات بنر
              </li>
              <li
                onClick={() => handleMenuClick("blog")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "blog" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات بلاگ
              </li>
              <li
                onClick={() => handleMenuClick("footer")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "footer" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات فوتر
              </li>
              <li
                onClick={() => handleMenuClick("support")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "support" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تایم های رزرو شده
              </li>
            </>
          )}
          {userData.role === 'editor' && (
            <>
              <li
                onClick={() => handleMenuClick("reports")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "reports" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات پیامک ها
              </li>
              <li
                onClick={() => handleMenuClick("baner")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "baner" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات بنر
              </li>
              <li
                onClick={() => handleMenuClick("blog")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "blog" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات بلاگ
              </li>
              <li
                onClick={() => handleMenuClick("footer")}
                className={`p-3 cursor-pointer transition-all transform ${activePage === "footer" ? "translate-x-0 bg-[#1C2FAF] border-l-4 border-white" : "translate-x-10"} hover:bg-[#1C2FAF] rounded-lg`}
              >
                تنظیمات فوتر
              </li>
            </>
          )}
        </ul>
      </div>

      <button
        className="md:hidden fixed top-4 left-4 z-50 p-2 bg-[#3864F2] text-white rounded-md"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
      </button>

      <div className="flex-1 md:p-8 bg-[#e4ebfe]">
        {activePage === "account" && (
          <div className="shadow-lg rounded-lg p-6 bg-white">
            <h1 className="text-3xl font-bold text-[#1C2FAF] mb-6">پنل کاربری: {userData.username}</h1>
            <div>
              <p className="text-xl mb-4"><strong>پسورد:</strong> *******</p>
              <p className="text-xl mb-4"><strong>سطح دسترسی:</strong> {userData.role}</p>
              <button
                onClick={handleLogout}
                className="mt-6 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-all"
              >
                خروج
              </button>
            </div>

            <h2 className="text-2xl font-semibold mt-6">تغییر رمز عبور</h2>
            <form onSubmit={handleChangePassword} className="mt-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">رمز عبور قبلی</label>
                <input
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">رمز عبور جدید</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">تکرار رمز عبور جدید</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                  required
                />
              </div>
              <button
                type="submit"
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-all"
              >
                تغییر رمز عبور
              </button>
            </form>
            {message && <p className="mt-4 text-red-600">{message}</p>}
          </div>
        )}
        {activePage === "manageDates" && <ManageDates />}
        {activePage === "admins" && <AdminCreate />}
        {activePage === "settings" && <AddTime />}
        {activePage === "reports" && <ChatboxEditor />}
        {activePage === "baner" && <BaberManager />}
        {activePage === "blog" && <BlogManager />}
        {activePage === "footer" && <FooterManager />}
        {activePage === "support" && <UserInfo />}
      </div>
    </div>
  );
};

export default Panel;