import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
const TextEditor = () => {
  const [englishText, setEnglishText] = useState('');
  const [farsiText, setFarsiText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Fetch existing texts from the API when the component mounts
    const fetchTexts = async () => {
      try {
        const response = await fetch('https://api.asmary.com/baner/texts');
        if (!response.ok) {
          throw new Error('Failed to fetch texts');
        }
        const data = await response.json();
        setEnglishText(data.texts.english);
        setFarsiText(data.texts.farsi);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchTexts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch('https://api.asmary.com/baner/texts', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          english: englishText,
          farsi: farsiText,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update texts');
      }

      setSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-[100%] overflow-scroll mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">Edit Texts</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {success && <div className="text-green-500 mb-4">متن با موفقیت به‌روزرسانی</div>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">English Text</label>
          <input
            type="text"
            value={englishText}
            onChange={(e) => setEnglishText(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-500"
            placeholder="Enter English text"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Farsi Text</label>
          <input
            type="text"
            value={farsiText}
            onChange={(e) => setFarsiText(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-500"
            placeholder="متن فارسی را وارد کنید"
            required
          />
        </div>
        <button
          type="submit"
          className={`w-full ${loading ? 'bg-gray-400' : 'bg-blue-500'} text-white py-2 rounded-md hover:bg-blue-600 transition duration-200`}
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update Texts'}
        </button>
      </form>
    </div>
  );
};

export default TextEditor;