import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment-jalaali"; // Ensure using moment-jalaali
import toast, { Toaster } from 'react-hot-toast';
const ManageDates = () => {
  const [persianDates, setPersianDates] = useState([]); // For newly selected dates
  const [dates, setDates] = useState([]); // Existing dates
  const [error, setError] = useState(null); // To show errors
  const [success, setSuccess] = useState(null); // To show success messages
  const [currentDate, setCurrentDate] = useState(moment());
  const [deleteMode, setDeleteMode] = useState(false); // For delete mode

  // Load dates from API
  const loadDates = async () => {
    try {
      const response = await axios.get("https://api.asmary.com/availableDates");

      // Convert and format dates correctly
      const formattedDates = response.data.map((item) => {
        if (typeof item === "string") {
          return moment(item, "jYYYY-jMM-jDD").format("jYYYY-jMM-jDD");
        } else {
          return moment(item.date, "jYYYY-jMM-jDD").format("jYYYY-jMM-jDD");
        }
      });

      setDates(formattedDates); // Store existing dates
    } catch (error) {
      setError("خطا در بارگذاری تاریخ‌ها. لطفاً دوباره تلاش کنید.");
    }
  };

  // Add new dates to the server
  const addDates = async () => {
    if (persianDates.length === 0) {
      setError("لطفاً تاریخ‌ها را وارد کنید.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.asmary.com/availableDates/add",
        {
          persianDates,
        }
      );
      toast.success("تاریخ با موفقیت اضافه شد."); // نمایش پیام موفقیت
      setPersianDates([]);
      loadDates();
    } catch (error) {
      setError(error.response ? error.response.data.error : "خطای نامشخص");
    }
  };

  // Delete dates from the server
  const deleteDates = async () => {
    if (persianDates.length === 0) {
      setError("لطفاً تاریخ‌ها را برای حذف انتخاب کنید.");
      return;
    }

    try {
      const response = await axios.delete(
        "https://api.asmary.com/availableDates/delete",
        {
          data: { persianDates },
        }
      );
      toast.success("تاریخ با موفقیت حذف شد."); // نمایش پیام موفقیت
      setPersianDates([]);
      loadDates();
    } catch (error) {
      setError(error.response ? error.response.data.error : "خطای نامشخص");
    } finally {
      setDeleteMode(false);
    }
  };

  const goToNextMonth = () =>
    setCurrentDate(currentDate.clone().add(1, "jmonth"));
  const goToPreviousMonth = () =>
    setCurrentDate(currentDate.clone().subtract(1, "jmonth"));

  useEffect(() => {
    loadDates();
  }, []);

  const handleDateClick = (formattedDate) => {
    setPersianDates((prevState) =>
      prevState.includes(formattedDate)
        ? prevState.filter((date) => date !== formattedDate)
        : [...prevState, formattedDate]
    );
  };

  return (
    <div className="mt-8 p-6 bg-white shadow-lg rounded-lg">
      <Toaster />
      <h2 className="text-2xl font-bold text-[#1C2FAF] mb-6">مدیریت تاریخ‌ها</h2>

      {/* {error && <div className="text-red-600 mb-4">{error}</div>}
      {success && <div className="text-green-600 mb-4">{success}</div>} */}

      <div className="p-4 bg-white shadow rounded-lg border-2 border-[#1C2FAF]">
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={goToPreviousMonth}
            className="p-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            قبلی
          </button>
          <h2 className="text-lg font-bold">
            {currentDate.format("jMMMM jYYYY")}
          </h2>
          <button
            onClick={goToNextMonth}
            className="p-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            بعدی
          </button>
        </div>

        <div className="grid grid-cols-7 text-center text-gray-600 mb-2">
          {[
            "شنبه",
            "یکشنبه",
            "دوشنبه",
            "سه‌شنبه",
            "چهارشنبه",
            "پنجشنبه",
            "جمعه",
          ].map((day, index) => (
            <div key={index} className="font-medium">
              <span className="block md:hidden">{day.charAt(0)}</span> {/* فقط حرف اول در موبایل */}
              <span className="hidden md:block">{day}</span> {/* نام کامل در دسکتاپ */}
            </div>
          ))}
        </div>

        <div className="grid grid-cols-7 gap-1">
          {(() => {
            const startOfMonth = currentDate.clone().startOf("jMonth"); // شروع ماه
            const endOfMonth = currentDate.clone().endOf("jMonth"); // پایان ماه
            const daysInMonth = endOfMonth.jDate(); // تعداد روزهای ماه

            // محاسبه روز اول ماه
            const startDayOfMonth = startOfMonth.day(); // روز اول ماه

            // محاسبه روزهای خالی
            const emptyDays = Array.from({ length: startDayOfMonth === 0 ? 6 : startDayOfMonth + 1 });

            // ایجاد آرایه روزهای تقویم
            const calendarDays = [
              ...emptyDays,
              ...Array.from({ length: daysInMonth }, (_, index) => startOfMonth.clone().jDate(index + 1)),
            ];
            return calendarDays.map((day, index) => {
              const formattedDate = day
                ? day.format("jYYYY-jMM-jDD")
                : null;
              const isGreen = day && dates.includes(formattedDate);
              const isSelected = day && persianDates.includes(formattedDate);

              return (
                <div
                  key={index}
                  className={`p-1 text-sm cursor-pointer text-center rounded ${day
                    ? isGreen
                      ? "bg-green-200"
                      : "bg-gray-200"
                    : ""
                    } ${isSelected ? "border-2 border-green-500" : ""} ${deleteMode && isSelected ? "bg-red-200 border-red-500" : ""
                    }`}
                  onClick={() => day && handleDateClick(formattedDate)}
                >
                  {day ? day.jDate() : ""}
                </div>
              );
            });
          })()}
        </div>

        <div className="mt-4 flex flex-col md:flex-row gap-2">
          {!deleteMode ? (
            <>
              <button
                onClick={addDates}
                className="p-2 text-sm md:p-3 md:text-base bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                افزودن تاریخ‌ها
              </button>
              <button
                onClick={() => setDeleteMode(true)}
                className="p-2 text-sm md:p-3 md:text-base bg-red-500 text-white rounded hover:bg-red-600"
              >
                حذف تاریخ‌ها
              </button>
            </>
          ) : (
            <>
              <button
                onClick={deleteDates}
                className="p-2 text-sm md:p-3 md:text-base bg-red-600 text-white rounded hover:bg-red-700"
              >
                تایید حذف
              </button>
              <button
                onClick={() => setDeleteMode(false)}
                className="p-2 text-sm md:p-3 md:text-base bg-gray-400 text-white rounded hover:bg-gray-500"
              >
                انصراف
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageDates;
