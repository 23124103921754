import React from "react"; // اضافه کردن ایمپورت React
import ReactDOM from "react-dom/client"; // استفاده از createRoot
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App.js";
import { BrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import { PostHogProvider } from "posthog-js/react";

// تنظیمات PostHog
const posthogOptions = {
  api_host: "https://us.i.posthog.com", // آدرس سرور PostHog
};

// تنظیمات Google Tag Manager
const tagManagerArgs = {
  gtmId: "GTM-5N8RV5FX",
};
TagManager.initialize(tagManagerArgs);

// ایجاد ریشه جدید با استفاده از createRoot
const root = ReactDOM.createRoot(document.getElementById("root"));

// استفاده از root.render به جای ReactDOM.render
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey="phc_y8UKdr8a700qSwkRHwbi46AZuNGM2ZXVIhxxbNr5Ysi" // کلید API PostHog
      options={posthogOptions}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>
);
