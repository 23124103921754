import React, { useEffect, useState } from 'react';
import './baner.css';

const Banner = () => {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [showText, setShowText] = useState(false); // کنترل نمایش متن‌ها
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.asmary.com/baner/texts'); // آدرس API
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setText1(data.texts.english); // متن انگلیسی
        setText2(data.texts.farsi); // متن فارسی
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // بعد از یک تأخیر کوتاه، متن‌ها واضح می‌شوند
    const timer = setTimeout(() => setShowText(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div></div>;
  }

  return (
    <div className={`banner-container ${showText ? "show-text" : ""}`}>
      <div className="octagon-banner1">
        <div className="scrolling-text-container">
          {/* محتوای تکراری برای seamless scroll */}
          {[...Array(35)].map((_, i) => (
            <React.Fragment key={`group1-${i}`}>
              <span className="banner-text">{text2}</span>
              <span className='mr-4 ml-4'>-</span>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="octagon-banner2">
        <div className="scrolling-text-container">
          {[...Array(30)].map((_, i) => (
            <React.Fragment key={`group2-${i}`}>
              <span className="banner-text">{text1}</span>
              <span className='mr-4 ml-4'>-</span>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Banner;