import React, { useEffect, useState } from "react";
import fLogo from "./asmarib.png";
import './footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios';

const Footer = () => {
  const [contactInfo, setContactInfo] = useState({});
  const [contact, setcontact] = useState({});
  const [quickLinks, setQuickLinks] = useState([]);
  const [socialLinks, setSocialLinks] = useState({});

  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const response = await axios.get('https://api.asmary.com/footer/api/footer');
        setContactInfo(response.data.contactInfo);
        setcontact(response.data.contact);
        setQuickLinks(response.data.quickLinks);
        setSocialLinks(response.data.socialLinks);
      } catch (error) {
        console.error("Error fetching footer data:", error);
      }
    };

    fetchFooterData();
  }, []);

  return (
    <div id="contactUs" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className="bshadow_footer"></div>
      <footer className="w-11/12 card_main text-[#1c2faf] py-10 z-10 " dir="rtl">
        <div className="container mx-auto px-6 lg:px-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 ">
            {/* بخش اول: لوگو و توضیحات */}
            <div className="p-6 shadow-neumorphism card_main ">
              <img src={fLogo} width={'100px'} style={{ position: 'relative', top: '-36px' }} alt="asmari" />
              <p className="relative bottom-[48px] mb-4 text-[#1c2faf]">
                {contact.contactFooter}
              </p>
            </div>

            {/* بخش دوم: لینک‌های سریع */}
            <div className="card_main p-6 shadow-neumorphism">
              <h3 className="text-xl font-semibold text-[#1c2faf]">لینک‌های سریع</h3>
              <ul className="mt-4 space-y-2">
                {quickLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link.url} className="hover:text-[#3864F2] transition">
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* بخش سوم: اطلاعات تماس */}
            <div className="card_main p-6 shadow-neumorphism">
              <h3 className="text-xl font-semibold text-[#1c2faf]">تماس با ما</h3>
              <p className="mt-4 text-[#1c2faf]">
                <span className="block">ایمیل: {contactInfo.email}</span>
                <span className="block">تلفن: {contactInfo.phone}</span>
              </p>
              <div className="flex space-x-4 mt-6 justify-end" dir="ltr">
                <a
                  href={socialLinks.facebook}
                  className="lishadow w-8 h-8 flex justify-center items-center rounded-full bg-[#3864F2] text-white hover:bg-[#1C2FAF] transition shadow-neumorphism-btn"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a
                  href={socialLinks.twitter}
                  className="lishadow w-8 h-8 flex justify-center items-center rounded-full bg-[#3864F2] text-white hover:bg-[#1C2FAF] transition shadow-neumorphism-btn"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href={socialLinks.linkedin}
                  className="lishadow w-8 h-8 flex justify-center items-center rounded-full bg-[#3864F2] text-white hover:bg-[#1C2FAF] transition shadow-neumorphism-btn"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="card_main_header w-11/12 p-3 mt-3 mb-3 text-center bg-[#1c2faf] text-[#e4ebfe]" dir="ltr">
        <div>
          تمامی حقوق متعلق به <span className="animated-text">آسمــــــــاری</span> است
        </div>
      </div>
    </div>
  );
};

export default Footer;